import axios from '../_helpers/axios';

export const sub_routeService = {
    get,
    add,
    edit,
    destroy,
    get_all,
    get_sub_route_by_route,
    get_sub_route_by_account,
};

async function get(data) {
  return new Promise((resolve, reject) => {
    axios.get('sub_routes/' + data)
    .then(resp => {
      if(resp.data && resp.data.data){
        resolve(resp.data.data)
      }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function add(data) {
  return new Promise((resolve, reject) => {
    axios.post('sub_routes', data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}

async function edit(data) {
  return new Promise((resolve, reject) => {
    axios.put('sub_routes/'+data.id, data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}

async function destroy(data) {
  return new Promise((resolve, reject) => {
    axios.delete('sub_routes/'+data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function get_all() {
  return new Promise((resolve, reject) => {
    axios.get('sub_routes')
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function get_sub_route_by_route(data) {
  return new Promise((resolve, reject) => {
    axios.post('sub_route_by_route', data)
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function get_sub_route_by_account(data) {
  return new Promise((resolve, reject) => {
    axios.post('sub_route_by_account', data)
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}
