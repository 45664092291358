import axios from '../_helpers/axios';

export const vehicleService = {
    get,
    add,
    edit,
    destroy,
    get_all,
    get_available_vehicles,
    get_vehicle_by_account,
    get_vehicle_by_route,
};

async function get(data) {
  return new Promise((resolve, reject) => {
    axios.get('vehicles/' + data)
    .then(resp => {
      if(resp.data && resp.data.data){
        resolve(resp.data.data)
      }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function add(data) {
  return new Promise((resolve, reject) => {
    axios.post('vehicles', data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}

async function edit(data) {
  return new Promise((resolve, reject) => {
    axios.put('vehicles/'+data.id, data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}

async function destroy(data) {
  return new Promise((resolve, reject) => {
    axios.delete('vehicles/'+data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function get_all() {
  return new Promise((resolve, reject) => {
    axios.get('vehicles')
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function get_available_vehicles(data) {
  return new Promise((resolve, reject) => {
    axios.post('available_vehicles', data)
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function get_vehicle_by_account(data) {
  return new Promise((resolve, reject) => {
    axios.post('vehicle_by_account', data)
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function get_vehicle_by_route(data) {
  return new Promise((resolve, reject) => {
    axios.post('vehicle_by_route', data)
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}
