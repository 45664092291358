import axios from '../_helpers/axios';

export const vehicle_sharingService = {
    add,
    destroy,
    get_vehicle_sharing_by_account,
};

async function add(data) {
  return new Promise((resolve, reject) => {
    axios.post('vehicle_sharings', data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error)
        return
      }
      reject(error)
    })
  })
}

async function destroy(data) {
  return new Promise((resolve, reject) => {
    axios.delete('vehicle_sharings/'+data)
    .then(resp => {
        resolve(resp)
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}

async function get_vehicle_sharing_by_account(data) {
  return new Promise((resolve, reject) => {
    axios.post('vehicle_sharing_by_account', data)
    .then(resp => {
        if(resp.data && resp.data.data){
          resolve(resp.data.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data && error.response.data.error) {
        reject(error.response.data.error.error_message)
        return
      }
      reject(error)
    })
  })
}
